import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiPhoneLine} from 'react-icons/ri'

const Contact = () => {

  return (
    <section id='contact'>
      <h5>Lass uns miteinander sprechen </h5>
      <h2>Kontaktiere mich</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>kontakt@mergim-rexha.de</h5>
            <a href="mailto:kontakt@mergim-rexha.de" rel="noreferrer" target="_blank">Sende mir eine Nachricht</a>
          </article>
          <article className="contact__option">
            <RiPhoneLine className='contact__option-icon'/>
            <h4>Telefonnummer</h4>
            <h5>+49 (0) 1774 950 544</h5>
            <a href="tel:+491774950544" rel="noreferrer" target="_blank">Jetzt anrufen</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        {/*
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Vollständiger Name' required />
          <input type="email" name='email' placeholder='Deine E-Mail' required />
          <textarea name="message" rows="7" placeholder='Deine Nachricht' required ></textarea>
          <button type='submit' className='btn btn-primary'>Nachricht senden</button>
        </form>
        */}
      </div>
    </section>
  )
};

export default Contact