import React from 'react'
import './about.css'
import ME from '../../assets/mergim-rexha-profilbild.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Zum Kennenlernen</h5>
      <h2>Über mich</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Erfahrung</h5>
              <small>15+ Jahre</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Kunden</h5>
              <small>250+</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projekte</h5>
              <small>420+</small>
            </article>
          </div>

          <p>
          Seit meinem 21. Lebensjahr entwickle ich eigene Projekte mit voller Begeisterung. Wenn ich mir eine Idee oder ein Ziel in den Kopf setze, lässt es mir keine Ruhe, bis ich diesen Gedanken in die Tat umgesetzt habe. Ein Problem zu nennen ist richtig. Daran zu arbeiten ist wichtig. Es zu lösen ist entscheidend.          </p>

          <a href="#contact" className='btn btn-primary'>Lass uns miteinander sprechen</a>
        </div>
      </div>
    </section>
  )
}

export default About