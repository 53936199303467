import React from 'react'
import './header.css'
import ME from '../../assets/mergim-rexha-ueber-mich.jpg'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header id="me">
      <div className="container header__container">
        <h5>Hi, ich bin</h5>
        <h1>Mergim Rexha</h1>
        <h2 className="subheadlin_header">Nicht viel reden. Einfach machen!</h2>
        <h5 className="text-light">Digital Entrepreneur 🏄‍♂️</h5>
        <HeaderSocial />

        <div className="me">
          <img src={ME} alt="mergim rexha profilbild" className="profil-picture" />
        </div>

        <a href="#contact" className='scroll__down'>Kontaktiere mich</a>
      </div>
    </header>
  )
}

export default Header
