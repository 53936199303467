import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaXing} from 'react-icons/fa'
import logo from '../../assets/mergim-rexha-logo-white.svg'
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <a href="https://mergim-rexha.de"><img href="https://mergim-rexha.de" src={logo} className="logo" alt="Logo Mergim Rexha" /></a>

      <ul className='permalinks'>
        <li><a href="#me">Home</a></li>
        <li><a href="#about">Über mich</a></li>
        <li><a href="#experience">Erfahrungen</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Kontakt</a></li>
        <li><Link to="/Impressum">Impressum</Link></li>
        <li><a href="/Privacy">Datenschutzerklärung</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/mergim-rexha/" rel="noreferrer" target="_blank"><BsLinkedin/></a>
        <a href="https://www.xing.com/profile/Mergim_Rexha3/cv" rel="noreferrer" target="_blank"><FaXing/></a>
        <a href="https://github.com/mergimrexha" rel="noreferrer" target="_blank"><FaGithub/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Mergim Rexha. Alle Rechte vorbehalten.</small>
      </div>
    </footer>
  )
}

export default Footer
