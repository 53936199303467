import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaXing} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/mergim-rexha/" rel="noreferrer" target="_blank"><BsLinkedin/></a>
        <a href="https://www.xing.com/profile/Mergim_Rexha3/cv" rel="noreferrer" target="_blank"><FaXing/></a>
        <a href="https://github.com/mergimrexha" rel="noreferrer" target="_blank"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials