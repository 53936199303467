import React from 'react'
import './experience.css'
import {BsDashLg} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Welche Fähigkeiten ich besitze</h5>
      <h2 className="headline-mb">Meine Erfahrungen</h2>
      
      <div className="container experience__container experience_tablet">
        <div className="experience__projektmanagement">
          <h3>Projektmanagement</h3>
          <div className="experience__content grid__template-40">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Wissensdokumentation</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Kosten / Nutzen Analyse</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Entscheidungsgrundladen</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Projektumsetzung</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Budgetplanung</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Projektinfrastruktur</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Prozessoptimierungen</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Produktdokumentation</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Wasserfallmethode</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Scrum</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Prince2</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Agile Umsetzung</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Zielgruppenanalyse</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Problemlösungen</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Risikoanalyse</h4>
              </div>
            </article>
          </div>
        </div>
        {/* END OF Projektmanagement */}

        <div className="experience__projektinfrastruktur">
          <h3>Projektinfrastruktur</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Confluence</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Jira</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Jira Service Management</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Github</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Gitlab</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Apache</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Netlify</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Docker</h4>
              </div>
            </article>
          </div>
          
        <div className="experience__frameworks div-mobile">
        <h3 className="mt1">Frameworks</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>React</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Bootstrap</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Aurelia</h4>
              </div>
            </article>
          </div>
        </div>
        </div>
        
        <div className="experience__programmiersprachen">
          <h3>Programmiersprachen</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Java</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Javascript <br />(Browser + NodeJS)</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>PHP</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>SQL</h4>
              </div>
            </article>
          </div>
        <div className="experience__prototyping div-mt4 div-mobile">
        <h3 className="mt1">Prototyping</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Balsamiq</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe XD</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Figma</h4>
              </div>
            </article>
          </div>
        </div>
        </div>

        

        <div className="experience__kreativ">
        <h3>Kreativ</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Blender</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Final Cut Pro X</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Unity</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe Lightroom</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe After Effects</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe Animate</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe Premiere</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe Photoshop</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe InDesign</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Adobe Illustrator</h4>
              </div>
            </article>
          </div>
        </div>

        

        <div className="experience__cms">
        <h3>CMS</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>AEM</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Wordpress</h4>
              </div>
            </article>
          </div>
          <div className="experience__sprachen div-mobile">
        <h3 className="mt1">Sprachen</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Deutsch</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Englisch</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Albanisch</h4>
              </div>
            </article>
          </div>
        </div>
        </div>
        <div className="experience__sonstiges">
        <h3>Sonstiges</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Apple Keynote</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Powerpoint / Excel / Word</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Windows / MacOS / Linux</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Automatisiertes Testen</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Styleguides</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Codeconventions</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Usability Tests</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>SEO</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>UX-Design</h4>
              </div>
            </article>
            <article className='experience__details'>
              <BsDashLg className='experience__details-icon' />
              <div>
                <h4>Design Thinking</h4>
              </div>
            </article>
          </div>
        </div>


      </div>
    </section>
  )
}

export default Experience