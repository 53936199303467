import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Impressum from './components/impressum/Impressum'
import Privacy from './components/privacy/Privacy'
import { Route, Routes, BrowserRouter } from 'react-router-dom'


const Index = () => (
  <>
    <Header />
    <Nav />
    <About />
    <Experience />
    <Portfolio />
    <Contact />
    <Footer />
  </>
);

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="Impressum" element={<Impressum />} />
        <Route path="Privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
